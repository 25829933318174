<template>
  <div class="v-body">
    <van-tree-select
      :items="items"
      :active-id.sync="activeId"
      :main-active-index.sync="activeIndex"
    />
  </div>
</template>

<script>
/**
 * jsqr demo
 */
export default {
  components:{
  },
  data: () => ({
      items:[
        {
          // 导航名称
          text: '所有城市',
          // .5.6 版本开始支持
          badge: 3,
          // 是否在导航名称右上角显示小红点
          dot: true,
          // 导航节点额外类名
          className: 'my-class',
          // 该导航下所有的可选项
          children: [
            {
              // 名称
              text: '温州',
              // id，作为匹配选中状态的标识符
              id: 1,
              children: [
                {
                  // 名称
                  text: '温州',
                  // id，作为匹配选中状态的标识符
                  id: 1,
                  // 禁用选项
                  disabled: true,
                },
                {
                  text: '杭州',
                  id: 2,
                }
              ]
            },
            {
              text: '杭州',
              id: 2,
              children: [
                {
                  // 名称
                  text: '温州',
                  // id，作为匹配选中状态的标识符
                  id: 1,
                  // 禁用选项
                  disabled: true,
                },
                {
                  text: '杭州',
                  id: 2,
                }
              ]
            }
          ]
        },
        {
          // 导航名称
          text: '所有城市1',
          // .5.6 版本开始支持
          badge: 3,
          // 是否在导航名称右上角显示小红点
          dot: true,
          // 导航节点额外类名
          className: 'my-class',
          // 该导航下所有的可选项
          children: [
            {
              // 名称
              text: '温州1',
              // id，作为匹配选中状态的标识符
              id: 1,
              children: [
                {
                  // 名称
                  text: '温州2',
                  // id，作为匹配选中状态的标识符
                  id: 1,
                  // 禁用选项
                  disabled: true,
                },
                {
                  text: '杭州2',
                  id: 2,
                }
              ]
            },
            {
              text: '杭州2',
              id: 2,
              children: [
                {
                  // 名称
                  text: '温州3',
                  // id，作为匹配选中状态的标识符
                  id: 1,
                  // 禁用选项
                  disabled: true,
                },
                {
                  text: '杭州3',
                  id: 2,
                }
              ]
            }
          ]
        }
      ],
      activeIds: [1],
      activeIndex: 0,
  }),
  mounted(){
  
  },
  methods: {
    
  }
};
</script>

<style lang="scss" scoped>
.v-body {
  padding: 20rpx;
}
</style>