<template>
  <div class="home">
    <div class="title">vant 项目组件</div>
    <van-cell-group title="文件组件">
      <div class="btns">
         <van-cell class="btn" title="头像上传" is-link @click="gotoPage('/avatar')" />
         <van-cell class="btn" title="扫码" is-link @click="gotoPage('/scan')" />
         <van-cell class="btn" title="树" is-link @click="gotoPage('/tree')" />
         <van-cell class="btn" title="人员选择" is-link @click="gotoPage('/classifyselect')" />
      </div>
    </van-cell-group>
     <div>{{message}}</div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Cell, CellGroup } from 'vant';
export default {
  name: 'Index',
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },
  data(){
    return {
      message: ''
    }
  },
  methods:{
    gotoPage(path){
      this.$router.push({path: path})
      
    }

  }
}
</script>

<style lang="scss" scoped>
  .home{
    height: calc(100vh);
    background: #fff;
    overflow: auto;
    box-sizing: border-box;
    .title{
      font-size: 18px;
      font-weight: 600;
      padding: 10px;
      text-align: left;
    }
    .btns{
      padding: 15px;
      .btn{
        border: 1px solid #ccc;
        border-radius: 30px;
        background: #f9f9f9;
        margin-bottom: 10px;
      }
    }
    
  }
</style>
